<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <v-form v-model="isValid" ref="managerReportForm">
          <v-row style="margin-top: 20px;">
            <v-col cols="12" sm="6">
              <BaseDateTimeInput v-model="startDate" :bottom="true" :left="true" :readonly="false" :rules="[rules.required]" dense outlined clearable prepend-inner-icon="event" :label="$t('accounting.lang_dailyReportStart')"/>
            </v-col>
            <v-col cols="12" sm="6">
              <BaseDateTimeInput v-model="endDate" :bottom="true" :left="true" :readonly="false" :rules="[rules.required]" dense outlined clearable prepend-inner-icon="event" :label="$t('accounting.lang_dailyReportEnd')"/>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-row style="margin-top: 50px;" align="center" justify="center">
      <v-col class="text-center" cols="12" sm="6">
        <v-card :disabled="loadingManagerPrinting || !isValid" :loading="loadingManagerPrinting" @click="printManagerReport" elevation="5">
          <div style="padding-top: 40px; padding-bottom: 40px;">
            <div class="text-center icons-container pa-3 blue lighten-5 mx-auto">
              <font-awesome-icon :icon="['fal' , 'user-tie']" class="success--text" prefix="fal" size="3x"/>
            </div>
            <v-card-title class="text-center">
              <span class="mx-auto">{{ $t('accounting.lang_printManagerReport') }}</span>
            </v-card-title>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {library} from '@fortawesome/fontawesome-svg-core';
import {faUserTie} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import {mapState} from "vuex";
import BaseDateTimeInput from '../../common/BaseDateTimeInput.vue';
import FileSaver from 'file-saver';

library.add(
    faUserTie
);

export default {
  name: "ManagerReportComponent",

  components: {
    'font-awesome-icon': FontAwesomeIcon,
    BaseDateTimeInput
  },

  data() {
    return {
      loadingManagerPrinting: false,
      startDate: '',
      endDate: '',
      isValid: false,
      rules: {
        required: v => !!v || this.$t('generic.lang_requiredField'),
      },
    }
  },

  computed: {
    ...mapState([
      'api'
    ]),
  },

  methods: {
    printManagerReport() {
      if(!this.$refs.managerReportForm.validate()) {
        return;
      }

      this.loadingManagerPrinting = true;
      this.axios.post(ENDPOINTS.ACCOUNTING.MANAGERREPORT.PRINTA4, {
            startDate: this.startDate.replace(" ", "T"),
            endDate: this.endDate.replace(" ", "T"),
          },
          {
            responseType: 'arraybuffer',
          }).then((res) => {
        //DOWNLOAD INVOICE AS PDF
        FileSaver.saveAs(new Blob([res.data], {
          type: "application/pdf"
        }), "Managerreport.pdf");

        this.loadingManagerPrinting = false;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.loadingManagerPrinting = false;
      })
    },

  }
}
</script>

<style scoped>
.icons-container {
  width: 70px !important;
  height: 70px !important;
  border-radius: 100px !important;
}
</style>